import SvgIcon from '@mui/material/SvgIcon';
import { LAVENDER_STEEL } from 'utils/theme';

const FilterIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 15 15"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '15px', height: props.height ?? '15px' }}
    >
      <mask
        id="path-1-outside-1_4299_2303"
        maskUnits="userSpaceOnUse"
        x="-0.0388184"
        y="0.613281"
        width="15"
        height="14"
        fill="black"
      >
        <rect fill="white" x="-0.0388184" y="0.613281" width="15" height="14" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5237 2.61328H1.96118L6.42868 7.48691V11.957L8.05368 12.7695V7.48828H8.05493L12.5237 2.61328Z"
        />
      </mask>
      <path
        d="M1.96118 2.61328V1.11328C1.36633 1.11328 0.827684 1.46479 0.588159 2.00929C0.348635 2.55378 0.453495 3.18837 0.855451 3.62687L1.96118 2.61328ZM12.5237 2.61328L13.6294 3.62687C14.0314 3.18837 14.1362 2.55378 13.8967 2.00929C13.6572 1.46479 13.1185 1.11328 12.5237 1.11328V2.61328ZM6.42868 7.48691H7.92868C7.92868 7.11162 7.788 6.74997 7.53441 6.47332L6.42868 7.48691ZM6.42868 11.957H4.92868C4.92868 12.5251 5.24968 13.0445 5.75786 13.2986L6.42868 11.957ZM8.05368 12.7695L7.38286 14.1111C7.84784 14.3436 8.40005 14.3188 8.84227 14.0455C9.2845 13.7722 9.55368 13.2894 9.55368 12.7695H8.05368ZM8.05368 7.48828V5.98828C7.22525 5.98828 6.55368 6.65985 6.55368 7.48828H8.05368ZM8.05493 7.48828V8.98828C8.47539 8.98828 8.87655 8.81181 9.16066 8.50187L8.05493 7.48828ZM1.96118 4.11328H12.5237V1.11328H1.96118V4.11328ZM7.53441 6.47332L3.06691 1.59969L0.855451 3.62687L5.32294 8.5005L7.53441 6.47332ZM7.92868 11.957V7.48691H4.92868V11.957H7.92868ZM8.7245 11.4278L7.0995 10.6153L5.75786 13.2986L7.38286 14.1111L8.7245 11.4278ZM6.55368 7.48828V12.7695H9.55368V7.48828H6.55368ZM8.05493 5.98828H8.05368V8.98828H8.05493V5.98828ZM11.418 1.59969L6.9492 6.47469L9.16066 8.50187L13.6294 3.62687L11.418 1.59969Z"
        fill={LAVENDER_STEEL}
        mask="url(#path-1-outside-1_4299_2303)"
      />
    </SvgIcon>
  );
};

export default FilterIcon;
