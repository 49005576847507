import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const CloseIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '20', height: props.height ?? '21' }}
    >
      <path
        d="M5.31912 5.57422L14.96 15.5742M14.96 5.57422L5.31912 15.5742"
        stroke={props.stroke ?? DARK_GREY}
        strokeOpacity="0.6"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CloseIcon;
