import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const XSquareIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '28px',
        height: props.height ?? '28px',
        fill: DARK_GREY,
      }}
    >
      <path d="M208,32H48A16,16,0,0,0,32,48V208a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm0,176H48V48H208V208ZM165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32Z"></path>
    </SvgIcon>
  );
};

export default XSquareIcon;
