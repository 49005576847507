import {
  Autocomplete,
  Box,
  CircularProgress,
  createFilterOptions,
  Divider,
  IconButton,
  InputAdornment,
  Popper,
  Stack,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from 'assets/img/icons/CloseIcon';
import SearchIcon from 'assets/img/icons/SearchIcon';
import { reset, setSearchValue } from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { suggestedClaims } from 'data/suggestedClaims';
import { isTooLongText } from 'pages/PlaygroundV2Page/helpers';
import { useCheckClaim } from 'pages/PlaygroundV2Page/hooks/useCheckClaim';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { BORDER_COLOR, LAVENDER_STEEL, LIGHT_BLACK } from 'utils/theme';

const CustomPopper = styled(Popper)({
  '& .MuiPaper-root': {
    boxShadow: 'none',
  },
});

const SearchFieldBody = () => {
  const dispatch = useDispatch();
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const checkClaim = useCheckClaim();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));
  const handleClaimCheck = useCallback(
    async (value: string) => {
      if (!isTooLongText(value) && value.length > 0) {
        // Use blur to hide the keyboard on mobile, only seems to work with delay
        setTimeout(() => {
          inputRef.current?.blur();
        }, 0);
        checkClaim(value);
      }
    },
    [checkClaim]
  );

  const handleInputChange = (event: React.SyntheticEvent, value: string) => {
    dispatch(setSearchValue(value));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleClaimCheck(searchValue);
    }
  };

  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      dispatch(setSearchValue(newValue));
      handleClaimCheck(newValue);
    }
  };

  const handleSearchClick = () => {
    handleClaimCheck(searchValue);
  };

  const filterOptions = createFilterOptions({
    limit: 4,
  });

  useEffect(() => {
    if (claimLoading && searchValue) {
      const timeoutId = setTimeout(() => {
        handleClaimCheck(searchValue);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [claimLoading, handleClaimCheck, searchValue]);

  return (
    <Autocomplete
      freeSolo // Allow any text input, not just the dropdown options
      options={
        searchValue.length >= 3 && !filteredClaim.evidence
          ? suggestedClaims
          : []
      } // Show options only once the user has typed 3 characters and if no results have been found
      value={searchValue}
      PopperComponent={CustomPopper}
      onInputChange={handleInputChange}
      onChange={handleOptionSelect}
      filterOptions={filterOptions}
      sx={{
        display: 'flex',
      }}
      // styling for text in auto-complete dropdown
      ListboxProps={{
        sx: {
          '& .MuiAutocomplete-option': {
            paddingLeft: '35px',
            fontWeight: 300,
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id="outlined-basic"
          placeholder="Write or paste your text here"
          variant="outlined"
          inputRef={inputRef}
          sx={{
            background: 'white',
            height:
              claimLoading || filteredClaim.evidence ? undefined : '175px',
            paddingX: { mobile: '16px', tablet: '30px', desktop: '30px' },
            paddingY: { mobile: '16px', tablet: '25px', desktop: '25px' },
            '& fieldset': { border: 'none' },
            borderTopRightRadius: '12px',
            borderTopLeftRadius: '12px',
            border: `1px solid ${BORDER_COLOR}`,
            borderBottom: '0px',
            borderBottomRightRadius: '0px',
            borderBottomLeftRadius: '0px',
          }}
          multiline
          onKeyDown={handleKeyDown}
          InputProps={{
            ...params.InputProps,
            style: {
              padding: 0,
              fontSize: '18px',
              color: LIGHT_BLACK,
            },
            endAdornment: (
              <InputAdornment position="end">
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  divider={
                    !isMobileScreen && (
                      <Divider orientation="vertical" flexItem />
                    )
                  }
                >
                  {searchValue.length > 0 && (
                    <IconButton
                      type="button"
                      sx={{
                        width: '30px',
                        height: '30px',
                      }}
                      aria-label="delete claim"
                      onClick={() => {
                        dispatch(reset());
                      }}
                    >
                      <CloseIcon stroke={LAVENDER_STEEL} />
                    </IconButton>
                  )}
                  <IconButton
                    type="button"
                    sx={{
                      border: '1px solid #6C727A30',
                      width: '42px',
                      height: '42px',
                      boxShadow: '4px 4px 17px -3px rgba(128, 155, 181, 0.13)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    aria-label="search"
                    disabled={isTooLongText(searchValue) || claimLoading}
                    onClick={handleSearchClick}
                  >
                    {claimLoading ? (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                          height: '100%',
                        }}
                      >
                        <CircularProgress
                          size={24}
                          sx={{ color: LAVENDER_STEEL }}
                        />
                      </Box>
                    ) : (
                      <SearchIcon stroke={LAVENDER_STEEL} />
                    )}
                  </IconButton>
                </Stack>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchFieldBody;
