import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface TokenInfo {
  token: string;
  expiresAt: Date | null;
}

export const useAccessToken = (): TokenInfo => {
  const [tokenInfo, setTokenInfo] = useState<TokenInfo>({
    token: '',
    expiresAt: null,
  });
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently();

        // Decode the JWT token to get expiration
        const tokenParts = accessToken.split('.');
        if (tokenParts.length === 3) {
          const payload = JSON.parse(atob(tokenParts[1]));
          const expiresAt = payload.exp ? new Date(payload.exp * 1000) : null;

          setTokenInfo({
            token: accessToken,
            expiresAt,
          });
        }
      } catch (error) {
        console.error('Error fetching access token:', error);
      }
    };
    fetchToken();
  }, [getAccessTokenSilently]);

  return tokenInfo;
};
