import SvgIcon from '@mui/material/SvgIcon';
import { LAVENDER_STEEL } from 'utils/theme';

const InfoIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '19px', height: props.height ?? '19px' }}
      fill="none"
    >
      <path
        d="M9.54102 12.7945H9.55024L9.54102 9.10352M9.54102 17.4082C14.1276 17.4082 17.8457 13.6901 17.8457 9.10352C17.8457 4.51696 14.1276 0.798828 9.54102 0.798828C4.95446 0.798828 1.23633 4.51696 1.23633 9.10352C1.23633 13.6901 4.95446 17.4082 9.54102 17.4082Z"
        stroke={props.stroke ?? LAVENDER_STEEL}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <circle
        cx="9.54102"
        cy="5.74707"
        r="0.75"
        fill={LAVENDER_STEEL}
        stroke={LAVENDER_STEEL}
        strokeWidth="0.5"
      />
    </SvgIcon>
  );
};

export default InfoIcon;
