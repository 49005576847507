import SvgIcon from '@mui/material/SvgIcon';

const ArrowUpRightIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 18 17"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '18px',
        height: props.height ?? '18px',
        fill: props.fill ?? 'none',
      }}
    >
      <g opacity="0.7">
        <path
          d="M4.95117 12.6094L13.4512 4.60938"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.54492 4.60938H13.4512V11.1094"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </SvgIcon>
  );
};

export default ArrowUpRightIcon;
