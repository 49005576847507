interface EnvironmentConfig {
  domain: string;
  clientId: string;
  redirectUri: string;
  apiBaseURL: string;
  docsUrl: string;
}

interface Config {
  environment: {
    development: EnvironmentConfig;
    testing: EnvironmentConfig;
    conference: EnvironmentConfig;
    pilot: EnvironmentConfig;
    production: EnvironmentConfig;
  };
  audience: string;
  timeout: number;
  scope: string;
}

const config: Config = {
  environment: {
    development: {
      domain: 'auth.factiverse.ai',
      clientId: 'a2eZpQv6jbHrLEAACLblp25mrtVRiLiE',
      redirectUri: 'http://localhost:4000/',
      apiBaseURL: 'https://prod.factiverse.ai/v1/',
      docsUrl: 'https://api.factiverse.ai/v1/docs',
    },
    testing: {
      domain: 'factiverse-dev.eu.auth0.com',
      clientId: 'dHm5NybI6o0aZBCYheT3G6UW1Jbi6KO2',
      redirectUri: 'https://test-editor.factiverse.ai/',
      apiBaseURL: 'https://dev.factiverse.ai/v1/',
      docsUrl: 'https://dev.factiverse.ai/v1/docs',
    },
    conference: {
      domain: 'factiverse-dev.eu.auth0.com',
      clientId: 'dHm5NybI6o0aZBCYheT3G6UW1Jbi6KO2',
      redirectUri: 'https://factcheckeditor.com/',
      apiBaseURL: 'https://dev.factiverse.ai/v1/',
      docsUrl: 'https://api.factiverse.ai/v1/docs',
    },
    pilot: {
      domain: 'auth.factiverse.ai',
      clientId: 'PKqkbPtLXMnFsK9iJfvWYl9Kx22e36jr',
      redirectUri: 'https://pilot.factiverse.ai/',
      apiBaseURL: 'https://azure.factiverse.ai/v1/',
      docsUrl: 'https://azure.factiverse.ai/v1/docs',
    },
    production: {
      domain: 'auth.factiverse.ai',
      clientId: 'a2eZpQv6jbHrLEAACLblp25mrtVRiLiE',
      redirectUri: 'https://editor.factiverse.ai/',
      apiBaseURL: 'https://prod.factiverse.ai/v1/',
      docsUrl: 'https://api.factiverse.ai/v1/docs',
    },
  },
  audience: 'https://factiverse/api',
  timeout: 500000,
  scope:
    'openid profile email offline_access post:feedback post:microfact get:microfact post:bias_detection post:claim_detection get:entity post:stance_detection post:claim_search',
};

export const getEnvironmentConfig = (): EnvironmentConfig => {
  const env = process.env.REACT_APP_DEPLOYMENT || 'development';
  return config.environment[env as keyof Config['environment']];
};

export const API_CONFIG = {
  ...getEnvironmentConfig(),
  audience: config.audience,
  scope: config.scope,
  timeout: config.timeout,
};

// Allowed domains from which postMessages can be received
export const ALLOWED_ORIGINS = [
  'http://localhost:4000',
  'https://test-editor.factiverse.ai',
  'https://editor.factiverse.ai',
  'https://pilot.factiverse.ai',
];
