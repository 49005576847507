import React from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from '@mui/material';
import Card from '../components/Card';
import Description from './Description';
import WarningIcon from 'assets/img/icons/WarningIcon';
import RetryClaimComponent from './RetryClaimComponent';
import CloseIcon from 'assets/img/icons/CloseIcon';
import { useAppSelector } from 'core/store/hooks';
import { ErrorCodes } from '../helpers';
import { useDispatch } from 'react-redux';
import { LAVENDER_STEEL } from 'utils/theme';
import { resetError } from 'core/store/claimsV2Slice';

const errorMessages = {
  [ErrorCodes.TIMEOUT]: {
    headerText: 'The connection has timed out',
    subHeaderText: 'The server is taking too long to respond.',
    showRetry: true,
  },
  [ErrorCodes.GENERIC_ERROR]: {
    headerText: 'An internal server error occurred',
    subHeaderText: 'Please try again later.',
    showRetry: true,
  },
};

const fallbackMessage = {
  headerText: 'An unexpected error occurred',
  subHeaderText: 'Please try again later.',
  showRetry: false,
};

const ErrorComponent: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));
  const { error } = useAppSelector((state) => state.claimsV2);
  const { headerText, subHeaderText, showRetry } =
    (error && errorMessages[error?.code]) || fallbackMessage;

  return (
    <Card
      paddingX={isMobileScreen ? '16px' : '30px'}
      paddingY={isMobileScreen ? '16px' : '30px'}
    >
      <Grid container>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              width="100%"
            >
              <Box display="flex" alignItems="flex-start" gap="8px">
                <Box>
                  <WarningIcon />
                </Box>
                <Typography fontSize="20px" fontWeight="500">
                  {headerText}
                </Typography>
              </Box>
              <IconButton
                type="button"
                sx={{
                  width: '30px',
                  height: '30px',
                }}
                aria-label="clear error"
                onClick={() => {
                  dispatch(resetError());
                }}
              >
                <CloseIcon stroke={LAVENDER_STEEL} data-testid="clear-error" />
              </IconButton>
            </Stack>
            <Typography fontSize="20px" fontWeight="500" pl="40px">
              {subHeaderText}
            </Typography>
            {error?.code === ErrorCodes.TIMEOUT && <Description />}
            {showRetry && <RetryClaimComponent />}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ErrorComponent;
