import SvgIcon from '@mui/material/SvgIcon';
import { LAVENDER_STEEL } from 'utils/theme';

const CopyIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 21 21"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '21px', height: props.height ?? '21px' }}
    >
      <path
        d="M14.5381 7.14258H3.28809C2.94291 7.14258 2.66309 7.4224 2.66309 7.76758V16.5176C2.66309 16.8628 2.94291 17.1426 3.28809 17.1426H14.5381C14.8833 17.1426 15.1631 16.8628 15.1631 16.5176V7.76758C15.1631 7.4224 14.8833 7.14258 14.5381 7.14258Z"
        strokeWidth="1.5"
        fill="none"
        stroke={props.stroke ?? LAVENDER_STEEL}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.16309 4.64258H17.0381C17.2038 4.64258 17.3628 4.70843 17.48 4.82564C17.5972 4.94285 17.6631 5.10182 17.6631 5.26758V14.6426"
        strokeWidth="1.5"
        fill="none"
        stroke={props.stroke ?? LAVENDER_STEEL}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default CopyIcon;
