import React from 'react';
import { Box } from '@mui/material';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { setupSyntaxHighlighter } from '../../utils/setupSyntaxHighlighter';

setupSyntaxHighlighter();

interface CodeHighlighterProps {
  code: string;
  language: string;
}

export const CodeHighlighter: React.FC<CodeHighlighterProps> = ({
  code,
  language,
}) => (
  <Box
    sx={{
      width: '100%',
      fontFamily: 'monospace',
      backgroundColor: '#1E1E1E',
      borderRadius: 1,
    }}
  >
    <SyntaxHighlighter
      language={language}
      style={vs2015}
      customStyle={{
        margin: 0,
        padding: '16px',
        background: 'transparent',
      }}
      wrapLongLines
    >
      {code}
    </SyntaxHighlighter>
  </Box>
);
