import { Stack, Typography, List, ListItem, Box } from '@mui/material';
import React from 'react';
import ManageSubscriptionButton from 'components/buttons/ManageSubscriptionButton';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from 'components/buttons/LoginButton';
import useUserMetaData from 'hooks/useUserMetaData';
import UpgradeSubscriptionButton from 'components/buttons/UpgradeSubscriptionButton';
import { Tier, Interval } from 'enums';
import { useAppSelector } from 'core/store/hooks';
import { clearPromoCode } from 'core/store/userSlice';
import { useDispatch } from 'react-redux';

type SubscriptionCard = {
  tier: Tier;
  subtitle: string;
  price: string;
  discount?: string;
  interval: Interval;
  featureList: string[];
};

const SubscriptionCard: React.FC<SubscriptionCard> = ({
  tier,
  subtitle,
  price,
  discount,
  interval,
  featureList,
}) => {
  const { isAuthenticated, user } = useAuth0();
  const { liteSubscription, proSubscription } = useUserMetaData();
  const { promoCode } = useAppSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleClearPromoCode = () => {
    dispatch(clearPromoCode());
  };

  return (
    <Stack spacing={2} maxWidth="288px">
      <Typography variant="h5" fontWeight="bold">
        {tier}
      </Typography>
      <Typography variant="subtitle1">{subtitle}</Typography>
      <Stack direction="row" spacing={2} alignItems="center" pt={2}>
        <Typography variant="h4" fontWeight="bold">
          {price}
        </Typography>
        <Typography variant="body1">
          {interval === Interval.Monthly ? 'per month' : 'per year'}
        </Typography>
        {discount && (
          <Box
            sx={{
              backgroundColor: 'white',
              transform: 'rotate(-15deg)',
            }}
            p={0.5}
          >
            <Typography fontWeight="bold">Save {discount}</Typography>
          </Box>
        )}
      </Stack>
      {isAuthenticated && (liteSubscription || proSubscription) ? (
        <ManageSubscriptionButton />
      ) : isAuthenticated && !(liteSubscription || proSubscription) ? (
        <UpgradeSubscriptionButton
          tier={tier as Tier}
          interval={interval as Interval}
          email={user?.email}
          promoCode={promoCode}
          clearPromoCode={handleClearPromoCode}
        />
      ) : (
        <LoginButton label="Log in to subscribe" />
      )}
      <Typography variant="body1">This includes:</Typography>
      <List sx={{ listStyleType: 'disc', paddingLeft: '2em' }} dense>
        {featureList.map((item, index) => {
          return (
            <ListItem key={index} sx={{ display: 'list-item' }}>
              <Typography variant="body1">{item}</Typography>
            </ListItem>
          );
        })}
      </List>
    </Stack>
  );
};

export default SubscriptionCard;
