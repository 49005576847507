import SvgIcon from '@mui/material/SvgIcon';

const WarningIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 32 33"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '32px',
        height: props.height ?? '32px',
        fill: props.fill ?? 'none',
      }}
    >
      <path
        d="M16 28.9287C22.6274 28.9287 28 23.5561 28 16.9287C28 10.3013 22.6274 4.92871 16 4.92871C9.37258 4.92871 4 10.3013 4 16.9287C4 23.5561 9.37258 28.9287 16 28.9287Z"
        stroke="#F8C408"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M16 10.9287V17.9287"
        stroke="#F8C408"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 23.9287C16.8284 23.9287 17.5 23.2571 17.5 22.4287C17.5 21.6003 16.8284 20.9287 16 20.9287C15.1716 20.9287 14.5 21.6003 14.5 22.4287C14.5 23.2571 15.1716 23.9287 16 23.9287Z"
        fill="#F8C408"
      />
    </SvgIcon>
  );
};

export default WarningIcon;
