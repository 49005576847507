import { Box, keyframes, Stack, Typography } from '@mui/material';
import InfoIcon from 'assets/img/icons/InfoIcon';
import { useEffect, useState } from 'react';
import { DARK_GREY, DARKER_GREY } from 'utils/theme';

const dotFlashing = keyframes` 
  0% {
    background-color: lightgray;
  }

  50%,100% {
    background-color:  ${DARKER_GREY};
  }
`;

export interface LoadingProps {
  labels: string[];
}

const Loading = (props: LoadingProps) => {
  const { labels } = props;
  const [currentLabel, setCurrentLabel] = useState(labels[0]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex < labels.length) {
          setCurrentLabel(labels[nextIndex]);
          return nextIndex;
        } else {
          clearInterval(timer);
          return prevIndex;
        }
      });
    }, 6000);

    return () => clearInterval(timer);
  }, [labels, currentIndex]);

  const DotComponent = ({ animationTime }: { animationTime: number }) => (
    <Box
      role="presentation"
      sx={{
        background: DARK_GREY,
        borderRadius: '50%',
        animation: `${dotFlashing} 1s infinite alternate`,
        animationDelay: `${animationTime}s`,
      }}
      height="5px"
      width="5px"
    />
  );

  const dots = [0, 0.5, 1];

  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <InfoIcon />
      <Typography variant="subtitle1" color="gray">
        {currentLabel}
      </Typography>
      <Stack flexDirection="row" gap={0.5} mt="6px">
        {dots.map((dot, key) => {
          return <DotComponent key={key} animationTime={dot} />;
        })}
      </Stack>
    </Stack>
  );
};

export default Loading;
