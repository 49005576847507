import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const MenuIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 19 10"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '19px', height: props.height ?? '10px' }}
    >
      <path
        d="M1.34277 0.859863H17.8428"
        stroke={props.stroke ?? DARK_GREY}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.34277 8.85986H17.8428"
        stroke={props.stroke ?? DARK_GREY}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default MenuIcon;
