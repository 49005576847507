import {
  filteredClaimResults,
  setClaimLoading,
  setError,
  resetError,
  setClaimResults,
  setIsNoDataFound,
  setLinearProgressValue,
} from 'core/store/claimsV2Slice';
import { useDispatch } from 'react-redux';
import { stanceDetectionRequest } from 'utils/API/stanceDetection';
import { ErrorCodes } from '../helpers';

export const useCheckClaim = () => {
  const dispatch = useDispatch();

  const checkClaim = (searchValue: string) => {
    if (searchValue.length === 0) return;
    dispatch(setClaimLoading(true));
    dispatch(resetError());
    stanceDetectionRequest(searchValue, true)
      .then((response) => {
        dispatch(setClaimResults(response.data));
        if (response.data.evidence.length) {
          dispatch(filteredClaimResults(response.data));
          dispatch(setIsNoDataFound(false));
        } else {
          dispatch(setIsNoDataFound(true));
        }
      })
      .catch((error) => {
        if (
          error.code === 'ECONNABORTED' &&
          error.message.includes('timeout')
        ) {
          console.error('Request timeout exceeded:', error.message);
          dispatch(
            setError({
              code: ErrorCodes.TIMEOUT,
              message: 'Request timeout exceeded',
            })
          );
        } else if (error.response && error.response.status === 500) {
          console.error('Internal Server Error:', error.message);
          dispatch(
            setError({
              code: ErrorCodes.GENERIC_ERROR,
              message:
                'An internal server error occurred. Please try again later.',
            })
          );
        }
      })
      .finally(() => {
        setTimeout(() => dispatch(setClaimLoading(false)), 1000);
        dispatch(setLinearProgressValue(100));
      });
  };

  return checkClaim;
};
