import { Box } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { useCheckClaim } from '../hooks/useCheckClaim';
import YellowBorderButton from 'components/buttons/YellowBorderButton';

const RetryClaimComponent = () => {
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);

  const timeOutError = useAppSelector((state) => state.claimsV2.error);
  const checkClaim = useCheckClaim();

  if (!timeOutError) {
    return null;
  }

  return (
    <Box width="100%">
      <YellowBorderButton
        sx={{
          width: { mobile: '100%', tablet: '138px' },
          height: { mobile: '56px', tablet: '44px' },
          marginLeft: { tablet: '40px', desktop: '40px' },
          borderRadius: '200px',
        }}
        onClick={() => checkClaim(searchValue)}
      >
        Try Again
      </YellowBorderButton>
    </Box>
  );
};

export default RetryClaimComponent;
