import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PublishedSinceDates,
  searchEngines,
  SourceDates,
  SourceTypes,
  sourceTypeValues,
} from 'pages/PlaygroundV2Page/helpers';

interface ErrorState {
  code: string;
  message: string;
}
interface AppState {
  claims: Claim[];
  searchValue: string;
  claim: Claim;
  filteredClaim: Claim;
  claimLoading: boolean;
  linearProgressValue: number;
  selectedSortingAccordion: SourceTypes;
  selectedSearchEngines: string[];
  openFilterPopover: boolean;
  isNoDataFound: boolean;
  selectedSourceTypes: SourceTypes[];
  selectedSourceDate: SourceDates;
  selectedPublishedSince: PublishedSinceDates;
  scrollTargetId: string | null;
  summary: Array<string>;
  fix: string;
  error: ErrorState | null;
  favoriteDomains: string[];
}

const initialState: AppState = {
  claims: [],
  searchValue: '',
  claim: {} as Claim,
  filteredClaim: {} as Claim,
  claimLoading: false,
  linearProgressValue: 0,
  selectedSortingAccordion: SourceTypes.ALL,
  selectedSearchEngines: searchEngines,
  openFilterPopover: false,
  isNoDataFound: false,
  selectedSourceTypes: sourceTypeValues,
  selectedSourceDate: SourceDates.MOST_RECENT,
  selectedPublishedSince: PublishedSinceDates.ANY,
  scrollTargetId: null,
  summary: [],
  fix: '',
  favoriteDomains: [],
  error: null,
};

const claimsV2Slice = createSlice({
  name: 'claimsV2',
  initialState,
  reducers: {
    setSearchValue: (state: AppState, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setClaimResults: (state: AppState, action: PayloadAction<Claim>) => {
      state.claim = action.payload;
    },
    filteredClaimResults: (state: AppState, action: PayloadAction<Claim>) => {
      state.filteredClaim = action.payload;
    },
    setClaimLoading: (state: AppState, action: PayloadAction<boolean>) => {
      state.claimLoading = action.payload;
    },
    incrementLinearProgress: (state: AppState) => {
      if (state.linearProgressValue >= 90) {
        return;
      }
      state.linearProgressValue += 10;
    },
    setLinearProgressValue: (
      state: AppState,
      action: PayloadAction<number>
    ) => {
      state.linearProgressValue = action.payload;
    },
    setSelectedSortingAccordion: (
      state: AppState,
      action: PayloadAction<SourceTypes>
    ) => {
      state.selectedSortingAccordion = action.payload;
    },
    setSelectedSourceTypes: (
      state: AppState,
      action: PayloadAction<SourceTypes[]>
    ) => ({
      ...state,
      selectedSourceTypes: action.payload,
    }),
    setSelectedSearchEngines: (
      state: AppState,
      action: PayloadAction<string[]>
    ) => {
      state.selectedSearchEngines = action.payload;
    },
    setSelectedSourceDate: (
      state: AppState,
      action: PayloadAction<SourceDates>
    ) => {
      state.selectedSourceDate = action.payload;
    },
    setSelectedPublishedSinceDate: (
      state: AppState,
      action: PayloadAction<PublishedSinceDates>
    ) => {
      state.selectedPublishedSince = action.payload;
    },
    setOpenFilterPopover: (state: AppState, action: PayloadAction<boolean>) => {
      state.openFilterPopover = action.payload;
    },
    setIsNoDataFound: (state: AppState, action: PayloadAction<boolean>) => {
      state.isNoDataFound = action.payload;
    },
    setScrollTarget: (
      state: AppState,
      action: PayloadAction<string | null>
    ) => {
      state.scrollTargetId = action.payload;
    },
    reset: (state) => ({
      ...initialState,
      favoriteDomains: state.favoriteDomains,
    }),
    setFavoriteDomains: (state: AppState, action: PayloadAction<string[]>) => {
      state.favoriteDomains = action.payload;
    },
    setError: (state: AppState, action: PayloadAction<ErrorState>) => {
      state.error = action.payload;
    },
    resetError: (state: AppState) => {
      state.error = null;
    },
  },
});

export const {
  setSearchValue,
  setClaimResults,
  filteredClaimResults,
  setClaimLoading,
  incrementLinearProgress,
  setLinearProgressValue,
  setSelectedSortingAccordion,
  setSelectedSourceTypes,
  setSelectedSearchEngines,
  setSelectedSourceDate,
  setSelectedPublishedSinceDate,
  setOpenFilterPopover,
  setIsNoDataFound,
  setScrollTarget,
  reset,
  setFavoriteDomains,
  setError,
  resetError,
} = claimsV2Slice.actions;
export default claimsV2Slice.reducer;
export const getInitialState = () => initialState;
