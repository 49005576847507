import { Tier, Interval } from 'enums';
import YellowBorderButton from './YellowBorderButton';
import { useUpgradeSubscription } from 'hooks/useUpgradeSubscription';
import React from 'react';

interface Props {
  tier: Tier;
  interval: Interval;
  email?: string; // the users email to pre-fill on the payment page
  promoCode?: string; // a promo code to pre-fill on the payment page
  clearPromoCode: () => void;
}

const UpgradeSubscriptionButton: React.FC<Props> = ({
  tier,
  interval,
  email,
  promoCode,
  clearPromoCode,
}) => {
  const { paymentUrl } = useUpgradeSubscription(
    tier,
    interval,
    email,
    promoCode
  );

  return (
    <YellowBorderButton
      onClick={() => {
        try {
          window.open(paymentUrl, '_blank');
          clearPromoCode();
        } catch (error) {
          console.error('Failed to open payment URL:', error);
        }
      }}
    >
      Upgrade subscription
    </YellowBorderButton>
  );
};

export default UpgradeSubscriptionButton;
