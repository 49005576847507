import React from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import CodeExamplesGrid from './examples/CodeExamplesGrid';
import DocsButton from './DocsButton';
interface ExamplesSectionProps {
  token: string;
  onCopy: () => void;
}

const ExamplesSection: React.FC<ExamplesSectionProps> = ({ token, onCopy }) => (
  <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: '800px' }}>
    <Stack spacing={2}>
      <Typography variant="h6">API Examples</Typography>
      <CodeExamplesGrid token={token} onCopy={onCopy} />
      <DocsButton onOpen={onCopy} token={token} />
    </Stack>
  </Paper>
);

export default ExamplesSection;
