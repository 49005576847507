import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

interface PageLayoutProps {
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => (
  <Stack alignItems="center" spacing={4} m="32px" p="40px">
    <Typography variant="h4">Developer Portal</Typography>
    <Typography variant="body1" textAlign="center" maxWidth="800px">
      Welcome to the developer portal. Here you can access your API credentials
      and explore our examples and API documentation.
    </Typography>
    {children}

    <Stack spacing={2} alignItems="center">
      <Typography variant="body1" textAlign="center" maxWidth="600px">
        Have questions or need assistance? Our team is here to help you
        integrate and make the most of our API.
      </Typography>
      <Button
        variant="outlined"
        startIcon={<EmailIcon />}
        onClick={() => (window.location.href = 'mailto:info@factiverse.ai')}
      >
        Contact Us
      </Button>
    </Stack>
  </Stack>
);

export default PageLayout;
