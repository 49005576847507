import { Skeleton, Stack } from '@mui/material';
import Export from './Export';

const ActionSkeleton = () => (
  <Stack direction="row" alignItems="center" gap={1}>
    <Skeleton variant="text" height="28px" width="58px" />
    <Skeleton variant="circular" height="38px" width="38px" />
  </Stack>
);

const ActionBox = ({ isLoading }: { isLoading: boolean }) => {
  return (
    <Stack direction="row" justifyContent="flex-end" spacing={3}>
      {isLoading ? <ActionSkeleton /> : <Export />}
    </Stack>
  );
};

export default ActionBox;
