import React, { useEffect, useState } from 'react';
import {
  Stack,
  Typography,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Skeleton,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface ApiCredentialsProps {
  token: string;
  onCopy: () => void;
  expiresAt: Date | null;
}

const ApiCredentials: React.FC<ApiCredentialsProps> = ({
  token,
  onCopy,
  expiresAt,
}) => {
  const [showToken, setShowToken] = useState(false);
  const [maskedToken, setMaskedToken] = useState('');
  const maxVisibleLength = 40;

  useEffect(() => {
    if (token) {
      const firstPart = token.slice(0, 6);
      const lastPart = token.slice(-4);
      const middlePart = '•'.repeat(Math.min(token.length - 10, 20));
      setMaskedToken(`${firstPart}${middlePart}${lastPart}`);
    }
  }, [token]);

  const handleCopyToken = () => {
    navigator.clipboard.writeText(token);
    onCopy();
  };

  const toggleTokenVisibility = () => {
    setShowToken(!showToken);
  };

  const formatToken = (token: string) => {
    if (token.length <= maxVisibleLength) return token;
    return `${token.slice(0, maxVisibleLength)}...`;
  };

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(date);
  };

  return (
    <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: '800px' }}>
      <Stack spacing={3}>
        <Typography variant="h6">API Credentials</Typography>
        {!token ? (
          <Skeleton variant="rectangular" width="100%" height={100} />
        ) : (
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell
                    component="th"
                    sx={{
                      width: '150px',
                      fontWeight: 'bold',
                      borderBottom: 'none',
                    }}
                  >
                    Access Token
                  </TableCell>
                  <TableCell
                    sx={{
                      fontFamily: 'monospace',
                      fontSize: '0.875rem',
                      borderBottom: 'none',
                      letterSpacing: '0.5px',
                      maxWidth: '400px',
                    }}
                  >
                    <Tooltip title={showToken ? token : ''} arrow>
                      <span
                        style={{
                          display: 'block',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {showToken ? formatToken(token) : maskedToken}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ width: '100px', borderBottom: 'none' }}
                  >
                    <Tooltip title={showToken ? 'Hide token' : 'Show token'}>
                      <span>
                        <IconButton
                          onClick={toggleTokenVisibility}
                          size="small"
                          sx={{ mr: 1 }}
                          disabled={!token}
                        >
                          {showToken ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Copy to clipboard">
                      <span>
                        <IconButton
                          onClick={handleCopyToken}
                          size="small"
                          disabled={!token}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                {expiresAt && (
                  <TableRow>
                    <TableCell
                      component="th"
                      sx={{
                        width: '150px',
                        fontWeight: 'bold',
                        borderBottom: 'none',
                      }}
                    >
                      Expires at
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: 'none',
                      }}
                    >
                      {formatDate(expiresAt)}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ width: '100px', borderBottom: 'none' }}
                    />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Paper>
  );
};

export default ApiCredentials;
