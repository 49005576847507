import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const MenuOpenIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 7 5"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '7px', height: props.height ?? '5px' }}
    >
      <path
        d="M5.91978 1.55751L3.57471 3.90259L1.22963 1.55751"
        strokeWidth="1.5px"
        stroke={props.stroke ?? DARK_GREY}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default MenuOpenIcon;
