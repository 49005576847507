import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOnlineStatus } from 'core/store/networkSlice';

const useNetworkStatus = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateNetworkStatus = () => {
      dispatch(setOnlineStatus(navigator.onLine));
    };

    updateNetworkStatus();

    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);
    window.addEventListener('load', updateNetworkStatus);

    return () => {
      window.removeEventListener('online', updateNetworkStatus);
      window.removeEventListener('offline', updateNetworkStatus);
      window.removeEventListener('load', updateNetworkStatus);
    };
  }, [dispatch]);

  return null;
};

export default useNetworkStatus;
