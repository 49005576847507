import React from 'react';
import { Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { API_CONFIG } from 'config';

interface DocsButtonProps {
  onOpen: () => void;
  token: string;
}

const DocsButton: React.FC<DocsButtonProps> = ({ onOpen, token }) => (
  <Button
    variant="outlined"
    endIcon={<OpenInNewIcon />}
    onClick={() => {
      window.open(`${API_CONFIG.docsUrl}?token=${token}`, '_blank');
      onOpen();
    }}
  >
    View API Documentation
  </Button>
);

export default DocsButton;
