import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import useDateFilter from 'hooks/useDateFilter';
import { PublishedSinceDates } from 'pages/PlaygroundV2Page/helpers';
import { ChangeEvent } from 'react';
import { DARKER_GREY } from 'utils/theme';

const RadioButton = ({ value, label }: { value: string; label: string }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio sx={{ py: 0.5 }} />}
      label={label}
      sx={{ color: DARKER_GREY }}
    />
  );
};

const PublishedSinceFilter = () => {
  const selectedPublishedSince = useAppSelector(
    (state) => state.claimsV2.selectedPublishedSince
  );

  const { updateClaimByPublishedDate } = useDateFilter();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateClaimByPublishedDate(
      (event.target as HTMLInputElement).value as PublishedSinceDates
    );
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="published-since-date-radio-buttons-group-label"
        value={selectedPublishedSince}
        name="published-since-date-buttons-group"
        onChange={onChange}
      >
        <RadioButton value={PublishedSinceDates.ANY} label="Any" />
        <RadioButton
          value={PublishedSinceDates.THREE_MONTHS}
          label="3 months"
        />
        <RadioButton value={PublishedSinceDates.SIX_MONTHS} label="6 months" />
        <RadioButton value={PublishedSinceDates.ONE_YEAR} label="1 years" />
        <RadioButton value={PublishedSinceDates.TWO_YEARS} label="2 years" />
      </RadioGroup>
    </FormControl>
  );
};

export default PublishedSinceFilter;
