import { Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { MAIN_BOX_SHADOW } from 'utils/theme';
import LinearProgressBar from './components/LinearProgressBar';
import SearchFieldBody from './components/SearchFieldBody';
import SearchFieldFooter from './components/SearchFieldFooter';

const SearchField = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  return (
    <Stack>
      <Stack
        boxShadow={MAIN_BOX_SHADOW}
        sx={{
          transition: 'border 0.3s, box-shadow 0.3s', // Smooth transitions
          border: '1px solid transparent', // Default border for consistent rendering
          borderRadius: claimLoading ? '12px 12px 0 0' : 3,
          '&:hover, &:focus-within': {
            border: '1px solid #6C727A70',
          },
        }}
      >
        <SearchFieldBody />
        <SearchFieldFooter />
      </Stack>
      <LinearProgressBar />
    </Stack>
  );
};

export default SearchField;
