const FREE_VERSION_WORD_COUNT = 30;
// const MIN_WORD_COUNT = 3;

export const wordsCount = (str) => {
  return str.trim().split(/\s+/).length;
};

export const isTooLongText = (searchValue: string) =>
  wordsCount(searchValue) > FREE_VERSION_WORD_COUNT;

export enum SourceDates {
  MOST_RECENT = 'MOST_RECENT',
  LEAST_RECENT = 'LEAST_RECENT',
}

export enum ErrorCodes {
  TIMEOUT = 'TIMEOUT',
  GENERIC_ERROR = 'GENERIC_ERROR',
}

export enum PublishedSinceDates {
  ANY = '0',
  THREE_MONTHS = '3',
  SIX_MONTHS = '6',
  ONE_YEAR = '12',
  TWO_YEARS = '24',
}

export const searchEngines = [
  'Google',
  'You.com',
  'Bing Search',
  'Wikipedia',
  'Semantic Scholar',
  'GoogleClaim',
  'ElasticSearch',
];

export enum SourceTypes {
  SUPPORTING = 'supporting',
  MIXED = 'mixed',
  DISPUTING = 'disputing',
  ALL = 'all',
}
enum SourceLabelDescription {
  SUPPORTS = 'SUPPORTS',
  DISPUTES = 'DISPUTES',
}
export const sourceTypeValues = [
  SourceTypes.DISPUTING,
  SourceTypes.MIXED,
  SourceTypes.SUPPORTING,
];

export const getErrorText = (searchValue: string) => {
  if (wordsCount(searchValue) > FREE_VERSION_WORD_COUNT) {
    return 'Text is too long, single claims of up to 30 words can be checked';
  }
};

export function areArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  return sortedArr1.every((value, index) => value === sortedArr2[index]);
}

export const shuffleArray = (array) => {
  return array
    .map((item) => ({ item, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ item }) => item);
};

export const sortSourcesByDate = (
  sources: Source[],
  selectedSourceDate: SourceDates
) => {
  const sourcesCopy = [...sources];
  const nullOldDate = new Date(70, 0, 1);
  const nullNewDate = new Date();
  if (selectedSourceDate === null) return sources;
  else if (selectedSourceDate === SourceDates.MOST_RECENT) {
    return sourcesCopy?.sort(
      (a, b) =>
        new Date(b.publishDate ?? nullOldDate).getTime() -
        new Date(a.publishDate ?? nullOldDate).getTime()
    );
  } else if (selectedSourceDate === SourceDates.LEAST_RECENT) {
    return sourcesCopy?.sort(
      (a, b) =>
        new Date(a.publishDate ?? nullNewDate).getTime() -
        new Date(b.publishDate ?? nullNewDate).getTime()
    );
  }
};

export const extractSummaryTextFromClaim = (claim: Claim) => {
  const { claim: claimText, evidence, summary } = claim;

  const supportingSources =
    evidence?.filter(
      (e) => e.labelDescription === SourceLabelDescription.SUPPORTS
    ).length || 0;
  const disputingSources =
    evidence?.filter(
      (e) => e.labelDescription === SourceLabelDescription.DISPUTES
    ).length || 0;
  const mixedSources =
    evidence && evidence.length - (supportingSources + disputingSources);

  const summaryText = `
  Claim: ${claimText}
  Supporting sources: ${supportingSources}, Mixed sources: ${mixedSources}, Disputing sources: ${disputingSources}
  ${
    summary && summary.length > 0
      ? summary.map((summaryItem) => `- ${summaryItem}`).join('\n')
      : 'No summary available.'
  }
`;

  return summaryText;
};

export const handleCopyToClipboard = async (textToCopy) => {
  try {
    await navigator.clipboard.writeText(textToCopy);
    return true;
  } catch (err) {
    console.error('Failed to copy text: ', err);
    return false;
  }
};

export function containsInteger(str) {
  const integerRegex = /\b\d+\b/;
  return integerRegex.test(str);
}
