import {
  filteredClaimResults,
  setSelectedPublishedSinceDate,
  setSelectedSearchEngines,
  setSelectedSourceDate,
  setSelectedSourceTypes,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  PublishedSinceDates,
  searchEngines,
  SourceDates,
  sourceTypeValues,
} from '../helpers';

const useFilterActions = () => {
  const dispatch = useDispatch();
  const claim = useAppSelector((state) => state.claimsV2.claim);

  const resetFilters = useCallback(() => {
    dispatch(setSelectedSearchEngines(searchEngines));
    dispatch(setSelectedSourceTypes(sourceTypeValues));
    dispatch(setSelectedSourceDate(SourceDates.MOST_RECENT));
    dispatch(setSelectedPublishedSinceDate(PublishedSinceDates.ANY));

    dispatch(filteredClaimResults(claim));
  }, [claim, dispatch]);

  return { resetFilters };
};

export default useFilterActions;
