import React from 'react';
import { Stack, Typography, Paper } from '@mui/material';

const TutorialSection: React.FC = () => (
  <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: '800px' }}>
    <Stack spacing={2}>
      <Typography variant="h6">Quick start</Typography>
      <div
        style={{
          position: 'relative',
          paddingBottom: '56.25%', // 16:9 aspect ratio
          height: 0,
          overflow: 'hidden',
        }}
      >
        <iframe
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
          src="https://www.youtube.com/embed/XSpUuK_5b4g?si=gPF4ky8SJh4Ocd68"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>
    </Stack>
  </Paper>
);

export default TutorialSection;
