import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import Card from 'pages/PlaygroundV2Page/components/Card';
import { SourceTypes } from 'pages/PlaygroundV2Page/helpers';
import useSourceTypes from 'pages/PlaygroundV2Page/hooks/useSourceTypes';
import React from 'react';
import { LIGHT_GREEN, LIGHT_PINK, LIGHT_YELLOW } from 'utils/theme';
import DonutChartLegend from './DonutChartLegend';

const palette = [LIGHT_GREEN, LIGHT_YELLOW, LIGHT_PINK];

const pieParams = { height: 140, margin: { right: 5 } };

export interface DonutChartItem {
  value: number;
  title: SourceTypes;
}

interface DonutChartProps {
  isLoading: boolean;
  evidence: Source[];
}

export const DonutChartMobileView: React.FC<DonutChartProps> = ({
  isLoading,
  evidence,
}) => {
  return (
    <Card padding="20px 16px">
      <DonutChart isLoading={isLoading} evidence={evidence} />
    </Card>
  );
};

const DonutChart: React.FC<DonutChartProps> = ({ isLoading, evidence }) => {
  const { supportingSources, mixedSources, disputingSources } =
    useSourceTypes(evidence);

  const donutChartData: DonutChartItem[] = [
    {
      value: supportingSources?.length ?? 0,
      title: SourceTypes.SUPPORTING,
    },
    {
      value: mixedSources?.length ?? 0,
      title: SourceTypes.MIXED,
    },
    {
      value: disputingSources?.length ?? 0,
      title: SourceTypes.DISPUTING,
    },
  ];

  const series = [{ data: donutChartData, innerRadius: 50, outerRadius: 70 }];

  return (
    <Stack
      spacing="20px"
      alignItems="center"
      sx={{
        justifyContent: {
          mobile: 'flex-start',
          tablet: 'center',
          desktop: 'center',
        },
      }}
    >
      {isLoading ? (
        <Skeleton variant="circular" width={140} height={140} />
      ) : (
        <Box
          sx={{
            position: 'relative',
            width: 140,
            height: 140,
          }}
        >
          <PieChart
            colors={palette}
            series={series}
            {...pieParams}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          />

          <Typography
            fontSize="16px"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              pointerEvents: 'none',
            }}
          >
            Summary
          </Typography>
        </Box>
      )}
      <DonutChartLegend
        data={donutChartData}
        palette={palette}
        isLoading={isLoading}
      />
    </Stack>
  );
};

export default DonutChart;
