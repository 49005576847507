import SvgIcon from '@mui/material/SvgIcon';

const InternetWarningIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 32 33"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '32px',
        height: props.height ?? '32px',
        fill: props.fill ?? 'none',
      }}
    >
      <path
        d="M12.0395 9.82261L2.76071 25.853C2.61223 26.1095 2.53394 26.4006 2.53369 26.697C2.53345 26.9934 2.61126 27.2846 2.75931 27.5414C2.90736 27.7981 3.12041 28.0113 3.37704 28.1596C3.63367 28.3079 3.92482 28.3859 4.2212 28.3859H22.7787C23.0751 28.3859 23.3662 28.3079 23.6228 28.1596C23.8795 28.0113 24.0925 27.7981 24.2406 27.5414C24.3886 27.2846 24.4664 26.9934 24.4662 26.697C24.4659 26.4006 24.3876 26.1095 24.2392 25.853L14.9604 9.82261C14.8122 9.56651 14.5992 9.3539 14.3429 9.20609C14.0865 9.05828 13.7958 8.98047 13.4999 8.98047C13.204 8.98047 12.9133 9.05828 12.657 9.20609C12.4007 9.3539 12.1877 9.56651 12.0395 9.82261Z"
        stroke="#D9D9D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 16.5742V20.793"
        stroke="#545454"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 25.8555C14.199 25.8555 14.7656 25.2888 14.7656 24.5898C14.7656 23.8909 14.199 23.3242 13.5 23.3242C12.801 23.3242 12.2344 23.8909 12.2344 24.5898C12.2344 25.2888 12.801 25.8555 13.5 25.8555Z"
        fill="#545454"
      />
    </SvgIcon>
  );
};

export default InternetWarningIcon;
