import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const ExpandLessIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 15 8"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '15px', height: props.height ?? '8px' }}
    >
      <path
        d="M14.0635 7.09229L7.81348 1.25062L1.56348 7.09229"
        strokeWidth="1.5"
        stroke={props.stroke ?? DARK_GREY}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default ExpandLessIcon;
