import { Stack } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { containsInteger } from 'pages/PlaygroundV2Page/helpers';
import { BORDER_COLOR } from 'utils/theme';
import LeftSide from './LeftSide';
import RightSide from './RightSide';

const SearchFieldFooter = () => {
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);

  const searchContainInteger = containsInteger(searchValue);

  return (
    <Stack
      direction="row"
      justifyContent={
        searchContainInteger || claimLoading ? 'space-between' : 'flex-end'
      }
      sx={{
        background: 'white',
        borderBottomRightRadius: claimLoading ? '0px' : '12px',
        borderBottomLeftRadius: claimLoading ? '0px' : '12px',
        border: `1px solid ${BORDER_COLOR}`,
        paddingX: { mobile: '16px', tablet: '30px', desktop: '30px' },
        paddingY: '12px',
      }}
      alignItems="center"
      spacing={{ mobile: 2, tablet: 10, desktop: 16 }}
    >
      <LeftSide />
      <RightSide />
    </Stack>
  );
};

export default SearchFieldFooter;
