import { AxiosResponse } from 'axios';
import { getAxiosInstance } from './apiClient';

export const Plan = {
  PRO: { value: 'pro', label: 'Pro Plan' },
  LITE: { value: 'lite', label: 'Lite Plan' },
  TRIAL: { value: 'trial', label: 'Trial Plan' },
  EXPIRED: { value: 'expired', label: 'Expired Plan' },
  M2M: { value: 'm2m', label: 'Machine-to-Machine Plan' },
} as const;

export type PlanType = (typeof Plan)[keyof typeof Plan]['value'];

interface RateLimitPlan {
  total_requests_allowed: number;
  total_concurrent_requests_allowed: number;
  time_window: string;
  plan: string;
  total_requests: number;
  total_concurrent_requests: number;
}

interface RateLimitEndpoint extends RateLimitPlan {
  endpoint: string;
}

export interface RateLimitResponse {
  plans: RateLimitPlan[];
  endpoints: RateLimitEndpoint[];
}

/**
 * Retrieve current rate limit configuration for user
 * @return {object} the api response
 */
export function getUserRateLimit(): Promise<AxiosResponse<RateLimitResponse>> {
  const api = getAxiosInstance();
  return api.get('rate-limit/me');
}
