import React, { useState } from 'react';
import { Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import { DiJava } from 'react-icons/di';
import { SiPython, SiJavascript, SiGo, SiCurl, SiDotnet } from 'react-icons/si';
import CodeModal from './CodeModal';

interface CodeExamplesGridProps {
  token: string;
  onCopy: () => void;
}

const languages = [
  { name: 'Python', icon: SiPython, color: '#3776AB' },
  { name: 'JavaScript', icon: SiJavascript, color: '#F7DF1E' },
  { name: 'Go', icon: SiGo, color: '#00ADD8' },
  { name: '.NET', icon: SiDotnet, color: '#512BD4' },
  { name: 'Java', icon: DiJava, color: '#007396' },
  { name: 'cURL', icon: SiCurl, color: '#073551' },
];

const CodeExamplesGrid: React.FC<CodeExamplesGridProps> = ({
  token,
  onCopy,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(0);

  return (
    <>
      <Grid container spacing={2}>
        {languages.map((lang, index) => (
          <Grid item xs={12} sm={6} md={4} key={lang.name}>
            <Card
              sx={{
                cursor: 'pointer',
                '&:hover': { transform: 'translateY(-4px)' },
                transition: 'transform 0.2s',
              }}
              onClick={() => {
                setSelectedLanguage(index);
                setModalOpen(true);
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <IconButton
                  sx={{
                    width: 60,
                    height: 60,
                    '& svg': { width: 32, height: 32, color: lang.color },
                  }}
                >
                  <lang.icon />
                </IconButton>
                <Typography variant="h6">{lang.name}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <CodeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        selectedLanguage={selectedLanguage}
        onLanguageChange={setSelectedLanguage}
        token={token}
        onCopy={onCopy}
      />
    </>
  );
};

export default CodeExamplesGrid;
