import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { DARKER_GREY, BRIGHT_BLUE } from 'utils/theme';
import { calculateMaxChars } from './helpers';

const ExpandedText = ({ text }: { text: string }) => (
  <Typography color={BRIGHT_BLUE} fontSize="18px" sx={{ display: 'inline' }}>
    {text}
  </Typography>
);

const SeeMoreButton = ({ name, text }: { name: string; text: string }) => {
  const [expanded, setExpanded] = useState<string>('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('tablet'));
  const lines = isMobile ? 4 : 3;
  const MAX_CHAR = calculateMaxChars(lines);

  const isExpanded = expanded === name;
  const displayedText = isExpanded ? text : `${text.slice(0, MAX_CHAR)}...`;

  const toggleExpand = () => {
    setExpanded(isExpanded ? '' : name);
  };

  return (
    <Box>
      <Box
        onClick={toggleExpand}
        sx={{
          cursor: 'pointer',
          display: 'inline',
          whiteSpace: 'pre-wrap',
          color: DARKER_GREY,
        }}
      >
        <Typography
          fontSize="18px"
          sx={{
            display: 'inline',
            whiteSpace: 'pre-wrap',
          }}
        >
          {text.length > MAX_CHAR && !isExpanded ? displayedText : text}
        </Typography>
      </Box>

      {text && text.length > MAX_CHAR && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            toggleExpand();
          }}
          sx={{
            textTransform: 'none',
            color: BRIGHT_BLUE,
            fontSize: '18px',
            padding: 0,
            marginLeft: '8px',
            marginBottom: '6px',
            '&:hover': { background: 'none' },
          }}
          endIcon={
            isExpanded ? (
              <ExpandLessIcon sx={{ fontSize: '18px', color: BRIGHT_BLUE }} />
            ) : (
              <ExpandMoreIcon sx={{ fontSize: '18px', color: BRIGHT_BLUE }} />
            )
          }
        >
          <ExpandedText text={isExpanded ? 'See less' : 'See more'} />
        </Button>
      )}
    </Box>
  );
};

export default SeeMoreButton;
