import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import golang from 'react-syntax-highlighter/dist/esm/languages/hljs/go';
import csharp from 'react-syntax-highlighter/dist/esm/languages/hljs/csharp';
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java';

export const setupSyntaxHighlighter = () => {
  SyntaxHighlighter.registerLanguage('python', python);
  SyntaxHighlighter.registerLanguage('javascript', javascript);
  SyntaxHighlighter.registerLanguage('bash', bash);
  SyntaxHighlighter.registerLanguage('go', golang);
  SyntaxHighlighter.registerLanguage('csharp', csharp);
  SyntaxHighlighter.registerLanguage('java', java);
};
