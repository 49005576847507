import { Stack, Typography } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import useUserMetaData from 'hooks/useUserMetaData';
import { getErrorText, isTooLongText } from 'pages/PlaygroundV2Page/helpers';
import { GREY, RED } from 'utils/theme';
import GetProButton from '../../GetProButton';

const RightSide = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);

  const { noOrTrialSubscription } = useUserMetaData();

  return (
    <Stack
      direction={{ mobile: 'column', tablet: 'row' }}
      alignItems="center"
      spacing={1}
    >
      {!claimLoading && (
        <Typography
          variant="subtitle2"
          sx={{
            color: isTooLongText(searchValue) ? RED : GREY,
            pr: 1,
          }}
        >
          {getErrorText(searchValue) ?? 'Check single claims of up to 30 words'}
        </Typography>
      )}
      {noOrTrialSubscription && <GetProButton title="Get Pro" />}
    </Stack>
  );
};

export default RightSide;
