import React from 'react';
import { Snackbar } from '@mui/material';

interface CopySnackbarProps {
  open: boolean;
  onClose: () => void;
}

const CopySnackbar: React.FC<CopySnackbarProps> = ({ open, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={3000}
    onClose={onClose}
    message="Copied to clipboard"
  />
);

export default CopySnackbar;
