import React from 'react';
import {
  Box,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import OfflineIcon from 'assets/img/icons/OfflineIcon';
import InternetWarningIcon from 'assets/img/icons/InternetWarningIcon';
import Card from './PlaygroundV2Page/components/Card';
import Header from './PlaygroundV2Page/Header';

const NoInternetPage: React.FC = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <Grid container justifyContent="center" sx={{ background: '#F3F5F7' }}>
      <Box
        sx={{
          display: 'flex',
          height: '100vh',
          flexDirection: 'column',
          width: { mobile: '100%', tablet: '100%', desktop: '840px' },
          boxSizing: 'border-box',
          padding: { mobile: '16px', tablet: '24px', desktop: '24px' },
        }}
      >
        <Header />
        <Card
          sx={{
            paddingX: { mobile: '16px', tablet: '30px', desktop: '30px' },
            paddingY: { mobile: '16px', tablet: '30px', desktop: '30px' },
            marginTop: { mobile: 4, tablet: 6, desktop: 6 },
          }}
        >
          <Stack
            direction="column"
            alignItems="center"
            mt={3}
            sx={{
              gap: { desktop: 4, tablet: 4, mobile: 2 },
              marginBottom: 12,
            }}
          >
            <Box
              sx={{
                width: '70px',
                height: '70px',
                backgroundColor: '#ffffff',
                border: '1px solid #F1F2FB',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <OfflineIcon />
            </Box>

            <Box
              sx={{
                width: { desktop: '460px', tablet: '460px', mobile: '90%' },
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                mt={1}
                sx={{
                  justifyContent: {
                    desktop: 'flex-start',
                    tablet: 'flex-start',
                    mobile: 'center',
                  },
                }}
              >
                {!isMobileScreen && <InternetWarningIcon />}
                <Typography
                  fontWeight="500"
                  lineHeight="28px"
                  sx={{
                    fontSize: {
                      desktop: '24px',
                      tablet: '24px',
                      mobile: '18px',
                    },
                    textAlign: {
                      desktop: 'start',
                      tablet: 'start',
                      mobile: 'center',
                    },
                  }}
                >
                  Internet connection is not working
                </Typography>
              </Stack>

              <Typography
                fontWeight="400"
                sx={{
                  fontSize: { desktop: '20px', tablet: '20px', mobile: '16px' },
                  marginTop: {
                    desktop: '24px',
                    tablet: '24px',
                    mobile: '16px',
                  },
                  textAlign: {
                    desktop: 'start',
                    tablet: 'start',
                    mobile: 'center',
                  },
                }}
              >
                No internet connection was found. Check your connection and try
                again.
              </Typography>
            </Box>
          </Stack>
        </Card>
      </Box>
    </Grid>
  );
};

export default NoInternetPage;
