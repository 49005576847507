import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from '@mui/material';
import CalendarIcon from 'assets/img/filter/CalendarIcon';
import GlobeIcon from 'assets/img/filter/GlobeIcon';
import ExpandMoreIcon from 'assets/img/icons/ExpandMoreIcon';
import { DARKER_GREY, SOFT_BLUE } from 'utils/theme';
import DateFilter from './components/DateFilter';
import PublishedSinceFilter from './components/PublishedSinceFilter';
import SearchEngineFilter from './components/SearchEngineFilter';

const AccordionSortComponent = ({
  title,
  icon,
  children,
}: {
  title: string;
  icon: JSX.Element;
  children: JSX.Element;
}) => {
  return (
    <Accordion defaultExpanded disableGutters sx={{ boxShadow: 'none' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          background: SOFT_BLUE,
          width: '439px',
          height: '40px',
          px: 4,
        }}
      >
        {icon}
        <Typography sx={{ left: 14, position: 'relative', color: DARKER_GREY }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 9, py: 1 }}>{children}</AccordionDetails>
    </Accordion>
  );
};

const PopoverBody = () => {
  return (
    <Stack>
      <AccordionSortComponent
        title="Select search engines"
        icon={<GlobeIcon />}
      >
        <SearchEngineFilter />
      </AccordionSortComponent>

      <AccordionSortComponent title="Sort by date" icon={<CalendarIcon />}>
        <DateFilter />
      </AccordionSortComponent>
      <AccordionSortComponent title="Published since" icon={<CalendarIcon />}>
        <PublishedSinceFilter />
      </AccordionSortComponent>
    </Stack>
  );
};

export default PopoverBody;
