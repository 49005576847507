const text =
  'I think we have seen a deterioration of values. US has the highest number of COVID infections ever.';

export const getCodeExamples = (token = 'YOUR_ACCESS_TOKEN') => ({
  python: `import requests

headers = {
    'Authorization': 'Bearer ${token}'
}

response = requests.post(
    'https://api.factiverse.ai/v1/claim_detection',
    headers=headers,
    json={
        'text': '${text}',
        'language': 'en'
    }
)

print(response.json())`,

  javascript: `fetch('https://api.factiverse.ai/v1/claim_detection', {
  method: 'POST',
  headers: {
    'Authorization': 'Bearer ${token}',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    text: '${text}',
    language: 'en'
  })
})
.then(response => response.json())
.then(data => console.log(data))`,

  go: `package main

import (
    "bytes"
    "encoding/json"
    "fmt"
    "net/http"
)

func main() {
    data := map[string]string{
        "text": "${text}",
        "language": "en",
    }

    jsonData, _ := json.Marshal(data)

    req, _ := http.NewRequest("POST", "https://api.factiverse.ai/v1/claim_detection", bytes.NewBuffer(jsonData))
    req.Header.Set("Authorization", "Bearer ${token}")
    req.Header.Set("Content-Type", "application/json")

    client := &http.Client{}
    resp, _ := client.Do(req)
    defer resp.Body.Close()

    var result map[string]interface{}
    json.NewDecoder(resp.Body).Decode(&result)
    fmt.Println(result)
}`,

  csharp: `using System;
using System.Net.Http;
using System.Text;
using System.Text.Json;
using System.Threading.Tasks;

var client = new HttpClient();
var request = new HttpRequestMessage
{
    Method = HttpMethod.Post,
    RequestUri = new Uri("https://api.factiverse.ai/v1/claim_detection"),
    Headers = {
        { "Authorization", "Bearer ${token}" },
    },
    Content = new StringContent(JsonSerializer.Serialize(new {
        text = "${text}",
        language = "en"
    }), Encoding.UTF8, "application/json")
};

var response = await client.SendAsync(request);
var json = await response.Content.ReadAsStringAsync();
Console.WriteLine(json);`,

  java: `import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.net.URI;

public class FactiverseExample {
    public static void main(String[] args) {
        String json = String.format("""
            {"text": "%s", "language": "en"}
            """, "${text}");

        HttpClient client = HttpClient.newHttpClient();
        HttpRequest request = HttpRequest.newBuilder()
            .uri(URI.create("https://api.factiverse.ai/v1/claim_detection"))
            .header("Authorization", "Bearer ${token}")
            .header("Content-Type", "application/json")
            .POST(HttpRequest.BodyPublishers.ofString(json))
            .build();

        client.sendAsync(request, HttpResponse.BodyHandlers.ofString())
            .thenApply(HttpResponse::body)
            .thenAccept(System.out::println);
    }
}`,

  bash: `curl https://api.factiverse.ai/v1/claim_detection \\
  -X POST \\
  -H 'Content-Type: application/json' \\
  -H 'Authorization: Bearer ${token}' \\
  -d '{"text": "${text}", "language": "en"}'`,
});
