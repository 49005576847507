import { Stack } from '@mui/material';
import ReadAloudButton from 'pages/PlaygroundV2Page/Claim/components/ClaimEvidence/ReadAloudButton';
import { extractSummaryTextFromClaim } from 'pages/PlaygroundV2Page/helpers';
import CopyToClipboardButton from '../CopyToClipboardButton';

interface SummaryFooterProps {
  claim: Claim;
}

const SummaryFooter: React.FC<SummaryFooterProps> = ({ claim }) => {
  const summary = claim.summary;

  const textToCopy = extractSummaryTextFromClaim(claim);
  const summaryText = Array.isArray(summary) ? summary.join(' ') : '';

  if (!summary || summary.length === 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      sx={{
        paddingX: { mobile: '8px', tablet: '30px', desktop: '30px' },
      }}
    >
      <ReadAloudButton text={summaryText} />
      <CopyToClipboardButton textToCopy={textToCopy} />
    </Stack>
  );
};

export default SummaryFooter;
