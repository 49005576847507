import { SvgIcon } from '@mui/material';
import { LAVENDER_STEEL } from 'utils/theme';

const GlobeIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        opacity="0.7"
        d="M9 17.085C13.4183 17.085 17 13.5032 17 9.08496C17 4.66668 13.4183 1.08496 9 1.08496M9 17.085C4.58172 17.085 1 13.5032 1 9.08496C1 4.66668 4.58172 1.08496 9 1.08496M9 17.085C6.74675 14.7649 5.44444 12.0228 5.44444 9.08496C5.44444 6.14707 6.74675 3.40501 9 1.08496M9 17.085C11.2532 14.7649 12.5556 12.0228 12.5556 9.08496C12.5556 6.14707 11.2532 3.40501 9 1.08496M16.1111 6.41829H1.88889M16.1111 11.7516H1.88889"
        stroke={LAVENDER_STEEL}
        fill="none"
        strokeLinejoin="round"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};

export default GlobeIcon;
