import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Tabs,
  Tab,
  Button,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CodeHighlighter } from './CodeHighlighter';
import { getCodeExamples } from '../../constants/codeExamples';

interface CodeModalProps {
  open: boolean;
  onClose: () => void;
  selectedLanguage: number;
  onLanguageChange: (newValue: number) => void;
  token: string;
  onCopy: () => void;
}

const CodeModal: React.FC<CodeModalProps> = ({
  open,
  onClose,
  selectedLanguage,
  onLanguageChange,
  token,
  onCopy,
}) => {
  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code);
    onCopy();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { minHeight: '70vh' },
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        Code Example
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Tabs
            value={selectedLanguage}
            onChange={(_, newValue) => onLanguageChange(newValue)}
          >
            <Tab label="Python" />
            <Tab label="JavaScript" />
            <Tab label="Go" />
            <Tab label=".NET" />
            <Tab label="Java" />
            <Tab label="cURL" />
          </Tabs>
          <CodeHighlighter
            code={Object.values(getCodeExamples(token))[selectedLanguage]}
            language={Object.keys(getCodeExamples(token))[selectedLanguage]}
          />
          <Button
            variant="outlined"
            startIcon={<ContentCopyIcon />}
            onClick={() =>
              handleCopyCode(
                Object.values(getCodeExamples(token))[selectedLanguage]
              )
            }
            sx={{ alignSelf: 'flex-start' }}
          >
            Copy Example
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default CodeModal;
