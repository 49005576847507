import SvgIcon from '@mui/material/SvgIcon';

const SearchIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '18px', height: props.height ?? '18px' }}
    >
      <path
        d="M12.5034 12.6342L17.0576 17.1885M7.76238 14.598C11.4653 14.598 14.4671 11.5962 14.4671 7.89324C14.4671 4.1903 11.4653 1.18848 7.76238 1.18848C4.05944 1.18848 1.05762 4.1903 1.05762 7.89324C1.05762 11.5962 4.05944 14.598 7.76238 14.598Z"
        stroke={props.stroke ? props.stroke : '#6F6F6F60'}
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};

export default SearchIcon;
