import React, { useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAccessToken } from './hooks/useAccessToken';
import PageLayout from './components/PageLayout';
import ApiCredentials from './components/ApiCredentials';
import ExamplesSection from './components/ExamplesSection';
import CopySnackbar from './components/CopySnackbar';
import TutorialSection from './components/TutorialSection';
import ApiUsage from './components/ApiUsage';

const DevelopersPage: React.FC = () => {
  const { token, expiresAt } = useAccessToken();
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleCopy = () => {
    setShowSnackbar(true);
  };

  return (
    <PageLayout>
      <ApiCredentials token={token} onCopy={handleCopy} expiresAt={expiresAt} />
      <ApiUsage />
      <ExamplesSection token={token} onCopy={handleCopy} />
      <TutorialSection />
      <CopySnackbar
        open={showSnackbar}
        onClose={() => setShowSnackbar(false)}
      />
    </PageLayout>
  );
};

// Wrap the component with withAuthenticationRequired
export default withAuthenticationRequired(DevelopersPage, {
  onRedirecting: () => (
    <PageLayout>
      <div>Loading...</div>
    </PageLayout>
  ),
});
