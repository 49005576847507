import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'core/store/hooks';
import { containsInteger } from 'pages/PlaygroundV2Page/helpers';
import Loading from '../../Loading';

const LeftSide = () => {
  const claimLoading = useAppSelector((state) => state.claimsV2.claimLoading);
  const searchValue = useAppSelector((state) => state.claimsV2.searchValue);

  const searchContainInteger = containsInteger(searchValue);

  if (!claimLoading && !searchContainInteger) {
    return null; // Render nothing
  }

  return (
    <Box data-testid="searchFieldFooterLeft">
      {claimLoading ? (
        <Loading
          labels={[
            'Detecting language',
            'Finding factual sentences',
            'Searching on Google',
            'Searching on Bing',
            'Searching on Semantic Scholar',
            'Browsing fact-checks',
            'Ranking results',
          ]}
        />
      ) : (
        <Typography fontSize="0.7rem">
          {searchContainInteger
            ? 'Always double check references when using numbers in your claims, as they are the most difficult to verify.'
            : ''}
        </Typography>
      )}
    </Box>
  );
};

export default LeftSide;
