import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AppState {
  allowTracking: boolean;
  seenTutorial: boolean;
  onboardingQuestions: OnboardingQuestion[];
  promoCode: string;
}

const initialState: AppState = {
  allowTracking: false,
  seenTutorial: true,
  onboardingQuestions: [],
  promoCode: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAllowTracking: (state: AppState, action: PayloadAction<boolean>) => {
      return { ...state, allowTracking: action.payload };
    },
    setSeenTutorial: (state: AppState, action: PayloadAction<boolean>) => {
      return { ...state, seenTutorial: action.payload };
    },
    setOnboardingQuestions: (
      state: AppState,
      action: PayloadAction<OnboardingQuestion[]>
    ) => {
      return { ...state, onboardingQuestions: action.payload };
    },
    setPromoCode: (state: AppState, action: PayloadAction<string>) => {
      return { ...state, promoCode: action.payload };
    },
    clearPromoCode: (state: AppState) => {
      return { ...state, promoCode: '' };
    },
  },
});

export const {
  setAllowTracking,
  setSeenTutorial,
  setOnboardingQuestions,
  setPromoCode,
  clearPromoCode,
} = userSlice.actions;
export default userSlice.reducer;
