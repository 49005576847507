import { Tier, Interval } from 'enums';
import { useEffect, useState } from 'react';

export const useUpgradeSubscription = (
  tier: Tier,
  interval: Interval,
  email?: string,
  promoCode?: string
) => {
  const [paymentUrl, setPaymentUrl] = useState('');

  const buildPaymentUrl = (tier: Tier, interval: Interval): string => {
    const baseUrl = 'https://payment.factiverse.no';
    const paymentUrls: Record<string, string> = {
      [`${Tier.Lite}${Interval.Monthly}`]: `${baseUrl}/b/8wM8ymeOl8lM74I7sw`,
      [`${Tier.Pro}${Interval.Monthly}`]: `${baseUrl}/b/3cs6qebC9gSi0Gk9AC`,
      [`${Tier.Lite}${Interval.Yearly}`]: `${baseUrl}/b/00g7ui21z31s60EfZ4`,
      [`${Tier.Pro}${Interval.Yearly}`]: `${baseUrl}/b/00gaGubC959AagU5kr`,
    };

    const key = `${tier}${interval}`;
    return paymentUrls[key];
  };

  useEffect(() => {
    let tempUrl = buildPaymentUrl(tier, interval);

    const params = new URLSearchParams();
    if (email) params.append('prefilled_email', email);
    if (promoCode) params.append('prefilled_promo_code', promoCode);
    if (params.toString()) {
      tempUrl += `?${params.toString()}`;
    }

    setPaymentUrl(tempUrl);
  }, [email, interval, promoCode, tier]);

  return { paymentUrl };
};
