import { Box, Button, Stack, Typography } from '@mui/material';
import useFilterActions from 'pages/PlaygroundV2Page/Filter/useFilterActions';
import { BORDER_COLOR, BRIGHT_BLUE, DARK_GREY } from 'utils/theme';

interface FilterHelperProps {
  claim: Claim;
  filteredClaim: Claim;
}

const FilterHelper = ({ claim, filteredClaim }: FilterHelperProps) => {
  const { resetFilters } = useFilterActions();

  const isFilterAdjusted =
    claim.evidence?.length !== filteredClaim.evidence?.length;

  const numberOfMissingSources =
    (claim.evidence?.length ?? 0) - (filteredClaim.evidence?.length ?? 0);

  return (
    <Box>
      {isFilterAdjusted && (
        <Stack
          borderTop="1px solid"
          borderColor={BORDER_COLOR}
          py={2}
          direction="row"
          alignItems="center"
        >
          <Button
            variant="contained"
            onClick={() => resetFilters()}
            sx={{
              bgcolor: BRIGHT_BLUE,
              borderRadius: '24px',
              boxShadow: 'none',
              textTransform: 'none',
            }}
          >
            Clear all filters
          </Button>
          {numberOfMissingSources && (
            <Typography variant="subtitle2" color={DARK_GREY} ml={2}>
              {numberOfMissingSources} source{numberOfMissingSources > 1 && 's'}{' '}
              {numberOfMissingSources > 1 ? 'are' : 'is'} hidden
            </Typography>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default FilterHelper;
