import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const SlackIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 41 41"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '41px', height: props.height ?? '41px' }}
    >
      <path
        d="M15.6241 22.6722C15.6241 24.0671 14.4967 25.1944 13.1019 25.1944C11.707 25.1944 10.5796 24.0671 10.5796 22.6722C10.5796 21.2773 11.707 20.1499 13.1019 20.1499H15.6241V22.6722Z"
        fill={DARK_GREY}
      />
      <path
        d="M16.8872 22.6722C16.8872 21.2773 18.0146 20.1499 19.4095 20.1499C20.8044 20.1499 21.9317 21.2773 21.9317 22.6722V28.9778C21.9317 30.3727 20.8044 31.5001 19.4095 31.5001C18.0146 31.5001 16.8872 30.3727 16.8872 28.9778V22.6722Z"
        fill={DARK_GREY}
      />
      <path
        d="M19.4095 12.5445C18.0146 12.5445 16.8872 11.4172 16.8872 10.0223C16.8872 8.62738 18.0146 7.5 19.4095 7.5C20.8044 7.5 21.9317 8.62738 21.9317 10.0223V12.5445H19.4095Z"
        fill={DARK_GREY}
      />
      <path
        d="M19.4081 13.8247C20.803 13.8247 21.9303 14.9521 21.9303 16.347C21.9303 17.7419 20.803 18.8692 19.4081 18.8692H13.0833C11.6884 18.8692 10.561 17.7419 10.561 16.347C10.561 14.9521 11.6884 13.8247 13.0833 13.8247H19.4081Z"
        fill={DARK_GREY}
      />
      <path
        d="M29.5171 16.347C29.5171 14.9521 30.6445 13.8247 32.0394 13.8247C33.4342 13.8247 34.5616 14.9521 34.5616 16.347C34.5616 17.7419 33.4342 18.8692 32.0394 18.8692H29.5171V16.347Z"
        fill={DARK_GREY}
      />
      <path
        d="M28.255 16.347C28.255 17.7419 27.1276 18.8693 25.7327 18.8693C24.3378 18.8693 23.2104 17.7419 23.2104 16.347V10.0223C23.2104 8.62738 24.3378 7.5 25.7327 7.5C27.1276 7.5 28.255 8.62738 28.255 10.0223V16.347Z"
        fill={DARK_GREY}
      />
      <path
        d="M25.7327 26.4556C27.1276 26.4556 28.255 27.5829 28.255 28.9778C28.255 30.3727 27.1276 31.5001 25.7327 31.5001C24.3378 31.5001 23.2104 30.3727 23.2104 28.9778V26.4556H25.7327Z"
        fill={DARK_GREY}
      />
      <path
        d="M25.7327 25.1944C24.3378 25.1944 23.2104 24.0671 23.2104 22.6722C23.2104 21.2773 24.3378 20.1499 25.7327 20.1499H32.0575C33.4524 20.1499 34.5798 21.2773 34.5798 22.6722C34.5798 24.0671 33.4524 25.1944 32.0575 25.1944H25.7327Z"
        fill={DARK_GREY}
      />
    </SvgIcon>
  );
};

export default SlackIcon;
