import React, { useEffect, useState } from 'react';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Skeleton,
  Tooltip,
  IconButton,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  RateLimitResponse,
  getUserRateLimit,
} from '../../../utils/API/rateLimit';

interface ApiUsageRowProps {
  label: string;
  requests: string;
  concurrent: string;
  window: string;
  endpoint?: string;
}

const ApiUsageRow: React.FC<ApiUsageRowProps> = ({
  label,
  requests,
  window,
  concurrent,
  endpoint,
}) => {
  const tooltipText = endpoint
    ? `Configuration for ${endpoint}`
    : `Configuration for all endpoints except endpoints listed below`;

  return (
    <TableRow>
      <TableCell
        component="th"
        scope="row"
        sx={{
          fontWeight: endpoint ? 'normal' : 'bold',
          borderBottom: 'none',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <span>{label}</span>
          {!endpoint && (
            <Tooltip title={tooltipText} arrow placement="right">
              <IconButton size="small" sx={{ padding: 0 }}>
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>last {window}</TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>{requests}</TableCell>
      <TableCell sx={{ borderBottom: 'none' }}>{concurrent}</TableCell>
    </TableRow>
  );
};

const LoadingState = () => (
  <>
    <Skeleton width="120px" height="32px" sx={{ mb: 2 }} />
    <Skeleton variant="rectangular" width="100%" height={200} />
  </>
);

const ApiUsage: React.FC = () => {
  const [rateLimit, setRateLimit] = useState<RateLimitResponse>();

  useEffect(() => {
    const fetchRateLimit = async () => {
      try {
        const response = await getUserRateLimit();
        setRateLimit(response.data);
      } catch (error) {
        console.error('Error fetching rate limit:', error);
      }
    };

    fetchRateLimit();
  }, []);

  const renderColumnHeader = (label: string, tooltip: string) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      {label}
      <Tooltip title={tooltip} arrow placement="top">
        <IconButton size="small" sx={{ padding: 0 }}>
          <HelpOutlineIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );

  if (!rateLimit) {
    return (
      <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: '800px' }}>
        <LoadingState />
      </Paper>
    );
  }

  // If there is no response mostly likely there is no rate limit configured
  if (rateLimit.plans.length === 0) {
    return null;
  }

  return (
    <Paper elevation={3} sx={{ p: 3, width: '100%', maxWidth: '800px' }}>
      <Typography variant="h6">API Usage</Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {renderColumnHeader(
                  'Time period',
                  'Period of time for the configuration'
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {renderColumnHeader(
                  'Requests',
                  'Number of requests made vs allowed in the time period'
                )}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {renderColumnHeader(
                  'Concurrent Requests',
                  'Number of simultaneous requests allowed in the time period'
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rateLimit.plans.map((plan, index) => (
              <ApiUsageRow
                key={`plan-${index}`}
                label="All endpoints"
                window={plan.time_window}
                requests={`${plan.total_requests} / ${plan.total_requests_allowed}`}
                concurrent={`${plan.total_concurrent_requests} / ${plan.total_concurrent_requests_allowed}`}
              />
            ))}

            {rateLimit.endpoints.map((endpoint, index) => (
              <ApiUsageRow
                key={`endpoint-${index}`}
                label={endpoint.endpoint}
                window={endpoint.time_window}
                endpoint={endpoint.endpoint}
                requests={`${endpoint.total_requests} / ${endpoint.total_requests_allowed}`}
                concurrent={`${endpoint.total_concurrent_requests} / ${endpoint.total_concurrent_requests_allowed}`}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ApiUsage;
