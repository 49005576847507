import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import SupportedIcon from 'assets/img/icons/SupportedIcon';

const descriptionItems = [
  'The app could be temporarily unavailable or too busy. Try again in a few moments.',
  'If you are unable to load any pages, check your computer´s network',
];

const Description: React.FC = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('tablet'));

  return (
    <Box>
      <Box>
        <Stack
          alignItems="flex-start"
          direction="column"
          mb={2}
          sx={{
            pl: { mobile: '40px', tablet: 0, desktop: 0 },
            mt: { mobile: 0, tablet: 1, desktop: 1 },
          }}
        >
          {descriptionItems.map((descriptionItem, key) => (
            <Box key={key} sx={{ display: 'flex', flexDirection: 'row' }}>
              {!isMobileScreen && (
                <Box ml="8px" mr="16px" pt="6px">
                  <SupportedIcon data-testid="supported-icon" />
                </Box>
              )}
              <Typography
                sx={{
                  fontSize: '18px',
                }}
              >
                {descriptionItem}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Description;
