import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const XIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: props.width ?? '25px', height: props.height ?? '25px' }}
    >
      <path
        d="M14.8443 10.6624L23.7788 0.5H21.6616L13.9038 9.32384L7.70759 0.5H0.561035L9.93087 13.8432L0.561035 24.5H2.67835L10.8709 15.1817L17.4145 24.5H24.561L14.8438 10.6624H14.8443ZM11.9443 13.9608L10.995 12.6321L3.44126 2.05962H6.69334L12.7893 10.5919L13.7386 11.9206L21.6626 23.0113H18.4106L11.9443 13.9613V13.9608Z"
        fill={DARK_GREY}
      />
    </SvgIcon>
  );
};

export default XIcon;
