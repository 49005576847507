import {
  filteredClaimResults,
  setSelectedPublishedSinceDate,
  setSelectedSourceDate,
} from 'core/store/claimsV2Slice';
import { useAppSelector } from 'core/store/hooks';
import moment from 'moment';
import {
  PublishedSinceDates,
  sortSourcesByDate,
  SourceDates,
} from 'pages/PlaygroundV2Page/helpers';
import { useDispatch } from 'react-redux';

export const useDateFilter = () => {
  const dispatch = useDispatch();
  const filteredClaim = useAppSelector((state) => state.claimsV2.filteredClaim);
  const claim = useAppSelector((state) => state.claimsV2.claim);

  const updateClaimByDate = (sourceDate: SourceDates) => {
    const newSourcesSortedByDate = sortSourcesByDate(
      filteredClaim.evidence ?? [],
      sourceDate
    );
    dispatch(setSelectedSourceDate(sourceDate));
    dispatch(
      filteredClaimResults({
        ...filteredClaim,
        evidence: newSourcesSortedByDate,
      })
    );
  };

  const updateClaimByPublishedDate = (
    publishedSinceDate: PublishedSinceDates
  ) => {
    dispatch(setSelectedPublishedSinceDate(publishedSinceDate));
    if (publishedSinceDate === PublishedSinceDates.ANY) {
      dispatch(
        filteredClaimResults({
          ...filteredClaim,
          evidence: claim.evidence,
        })
      );
    } else {
      const now = moment();
      const startDate = now.clone().subtract(publishedSinceDate, 'months');
      const newSourcesSortedByPublishedSinceDate = claim.evidence?.filter((e) =>
        moment(e.publishDate).isAfter(startDate)
      );

      dispatch(
        filteredClaimResults({
          ...filteredClaim,
          evidence: newSourcesSortedByPublishedSinceDate,
        })
      );
    }
  };

  return { updateClaimByDate, updateClaimByPublishedDate };
};

export default useDateFilter;
