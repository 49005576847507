import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY, LAVENDER_STEEL } from 'utils/theme';

const CloudIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 19 13"
      style={{ width: props.width ?? '19px', height: props.height ?? '13px' }}
    >
      <path
        d="M14.7352 5.50294C15.0268 4.68482 15.0066 3.78964 14.6784 2.98521C14.3503 2.18077 13.7366 1.52242 12.9526 1.13339C12.1686 0.744366 11.268 0.651455 10.4197 0.872108C9.57143 1.09276 8.83366 1.6117 8.34468 2.33185C7.88359 2.16714 7.38926 2.11467 6.90331 2.17868C6.41735 2.2427 5.954 2.42135 5.55259 2.69967C5.15118 2.97799 4.82337 3.34776 4.59688 3.77773C4.37039 4.20769 4.25194 4.68524 4.25135 5.17005C4.25131 5.28665 4.25824 5.40319 4.27223 5.51896C3.51431 5.61284 2.81705 5.97694 2.31133 6.54303C1.8056 7.10912 1.52617 7.83826 1.52539 8.59362C1.52531 9.00097 1.60636 9.4043 1.76392 9.78067C1.92148 10.157 2.15252 10.499 2.44373 10.7871C2.73494 11.0752 3.08065 11.3039 3.46118 11.4598C3.84171 11.6158 4.24963 11.6961 4.66155 11.6961H14.5463C15.3784 11.6961 16.1764 11.3692 16.7647 10.7874C17.3531 10.2056 17.6835 9.41645 17.6835 8.59362C17.6821 7.80417 17.3763 7.04496 16.8285 6.47071C16.2807 5.89646 15.5321 5.55034 14.7352 5.50294Z"
        stroke={LAVENDER_STEEL}
        fill="none"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        fill={DARK_GREY}
        d="M9.91682 6.75448V4.77246H9.29262V6.75448H7.90234L8.75324 7.83586L9.60414 8.91739L10.4555 7.83586L11.307 6.75448H9.91682Z"
      />
    </SvgIcon>
  );
};

export default CloudIcon;
