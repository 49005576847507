import SvgIcon from '@mui/material/SvgIcon';
import { DARK_GREY } from 'utils/theme';

const CheckSquareIcon = (props) => {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 256 256"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: props.width ?? '28px',
        height: props.height ?? '28px',
        fill: DARK_GREY,
      }}
    >
      <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208H208Z"></path>
    </SvgIcon>
  );
};

export default CheckSquareIcon;
