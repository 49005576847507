import { useAuth0 } from '@auth0/auth0-react';
import { Grid, Stack } from '@mui/material';
import { HighlightAs } from 'components/Editor';
import EditorSide from 'components/EditorSide';
import Login from 'components/Login';
import ResultsSide from 'components/ResultsSide';
import {
  setClaims,
  setNoClaimsFound,
  updateClaim,
} from 'core/store/claimsSlice';
import { useAppSelector } from 'core/store/hooks';
import useSettings from 'hooks/useSettings';
import useUserMetaData from 'hooks/useUserMetaData';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Editor as TinyMCEEditor } from 'tinymce';
import {
  clearHighlighting,
  getPosition,
  highlight,
} from 'utils/TinyMCE/TinyMCEHelper';
import TrialInfoMessage from './components/TrialInfoMessage';
import { stanceDetectionRequest } from 'utils/API/stanceDetection';

/**
 * This is the main page and langing page for recurring users, with the text editor and all Factiverse features.
 *
 * @return {React.FC} The page component
 */
const HomePage: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const [editor, setEditor] = useState<TinyMCEEditor | undefined>(undefined);
  const claims = useAppSelector((state) => state.claims.claims);
  const { noSubscription, trialSubscription } = useUserMetaData();

  const { allowTracking } = useSettings();

  // I see results even trial version has ended
  // I believe it is due to isAuthenticated check

  const checkClaim = (toCheck: Claim, force?: boolean) => {
    if (noSubscription) return;
    if (!force && (toCheck.isBeingChecked || toCheck.hasBeenChecked)) return;
    dispatch(
      updateClaim({
        claim: toCheck.claim,
        isBeingChecked: true,
      })
    );
    dispatch(setNoClaimsFound(false));
    stanceDetectionRequest(toCheck.claim, allowTracking, toCheck.resolved_claim)
      .then((response) => {
        dispatch(
          updateClaim({
            claim: toCheck.claim,
            id: response.data._id,
            evidence: response.data.evidence,
            finalScore: response.data.finalScore,
            // TODO: set this in a finally or catch in case there is an error
            isBeingChecked: false,
            hasBeenChecked: true,
            summary: response.data.summary,
            fix: response.data.fix,
          })
        );
      })
      .catch((error) => {
        console.error(error);
        dispatch(
          updateClaim({
            claim: toCheck.claim,
            id: undefined,
            evidence: undefined,
            finalScore: undefined,
            // TODO: set this in a finally or catch in case there is an error
            isBeingChecked: false,
            hasBeenChecked: true,
          })
        );
      });
  };

  useEffect(() => {
    const storedClaims = localStorage.getItem('claims');
    if (storedClaims !== null) {
      const parsedClaims = JSON.parse(storedClaims) as Claim[];
      dispatch(setClaims(parsedClaims));
      setTimeout(() => {
        parsedClaims.forEach((claim) => {
          if (claim.isBeingChecked) checkClaim(claim, true);
        });
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (editor === undefined) return;
    claims.forEach((claim) => {
      if (claim.position === undefined) {
        const position = getPosition(editor, claim.claim);
        dispatch(updateClaim({ claim: claim.claim, position: position }));
      }
      checkClaim(claim);
    });

    clearHighlighting(editor);
    claims.forEach((item) => {
      if (item.finalScore !== undefined) {
        const highlightAs =
          item.finalScore <= 0.4
            ? HighlightAs.Disputed
            : item.finalScore >= 0.6
            ? HighlightAs.Supported
            : HighlightAs.Mixed;
        highlight(editor, item.claim, highlightAs);
      } else {
        highlight(editor, item.claim, HighlightAs.FoundClaim);
      }
    });
    localStorage.setItem('claims', JSON.stringify(claims));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claims, editor]);

  return (
    <>
      {isAuthenticated && noSubscription && !trialSubscription && (
        <TrialInfoMessage />
      )}
      <Grid
        container
        justifyContent="space-between"
        direction={'row'}
        py={4}
        id="content"
      >
        <Grid item xs>
          <Stack direction={'column'}>
            <Login />
            <EditorSide
              allowTracking={allowTracking}
              editor={editor}
              setEditor={setEditor}
            />
          </Stack>
        </Grid>
        <ResultsSide />
      </Grid>
    </>
  );
};

export default HomePage;
